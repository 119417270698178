<template>
  <div class="content-main-top-container">
    <div class="lp-account-title">솔루션 이용신청 완료</div>
    <section class="section">
      <div class="container lp-account-container">
        <div class="lp-done-info">
          {{ basket.title }} 이용신청이 완료되었습니다
          <p>잠시만 기다리시면 등록하신 {{ email }}로 안내해드립니다</p>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="lp-account-menu-title">신청정보</div>
            <div class="lp-card">
              <div class="lp-card-title">테마 및 요금제</div>
              <div class="flex" style="margin-bottom: 32px">
                <div :style="imgStyle"></div>
                <div style="margin-left:12px">
                  <div class="lp-account-menu-title">{{ basket.title }}</div>
                  <div>{{ basket.priceTypeName }} / {{ basket.price[basket.priceType].billing|currencyNum }}원(월 정기결제)</div>
                </div>
              </div>

              <div class="lp-card-title">개설정보</div>

              <div>
                <div class="lp-input-box">{{ basket.serviceName }}</div>
                <div class="lp-input-box">{{ basket.url }}.launchpack.co.kr</div>
                <div class="lp-info">* 도메인은 개설 후 변경 가능합니다</div>
              </div>


            </div>
          </div>

          <div class="column is-6">
            <div class="lp-account-menu-title">결제정보</div>
            <div class="lp-card">
              <div class="lp-account-info-detail">
                <table class="table" style="width:100%">
                  <thead>
                  <tr>
                    <th>내역</th><th>기본금액</th><th>할인</th><th>금액</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="width:120px">이용료</td>
                      <td>687,500원</td>
                      <td>-177,500원</td>
                      <td>{{ basket.price[basket.priceType].billing|currencyNum }}원</td>
                    </tr>
                    <tr><td style="width:120px">설치비</td><td>0원</td><td>0원</td><td>0원</td></tr>
                  </tbody>
                </table>
              </div>

              <div class="lp-account-info">
                <div>총 결제금액(VAT 별도)</div><div><span class="lp-total-price">{{ basket.price[basket.priceType].billing|currencyNum }}</span>원</div>
              </div>
            </div>
            <div class="lp-card" style="margin-top: 12px">
              <table class="table" style="width:100%">
                <thead>
                <tr>
                  <th>결제방법</th><th>결제일</th><th>다음 결제 예정일</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td style="width:240px" class="has-text-left">
                    <div>카드결제</div>
                    <div>{{ account_card }}</div>
                  </td>
                  <td>{{ account_start_date }}</td>
                  <td>{{ account_next_date }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <button class="button" @click="$router.replace('/home')">확인</button>
          </div>
        </div>
        <div class="info">{{ info }}</div>
      </div>
    </section>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: "account",

    data() {
      return {
        serviceName: '',
        url: '',
        agree: {
          billing: false,
          limit: false
        },
        info: '정기결제 이용 시 가능한 결제수단은 신용카드입니다.\n' +
        '결제취소는 결제 후 7일 내 서비스 미 이용 시 가능하며, 고객센터(1566-7727)나 1:1문의로 신청하세요.\n' +
        '결제 후 7일 경과 또는 서비스 이용 시에는 중도 해지 및 이에 따른 환불이 가능합니다.\n' +
        '결제 수단의 정지/한도초과/정보변경 등의 사유로 결제상태가 변경될 경우, 결제 및 서비스가 일시 정지되며 정상으로 전환되거나 직접 일시 정지 해제를 하시면 다시 결제 및 서비스가 시작됩니다.'
      }
    },
    computed: {
      basket() {
        let basket = this.$store.getters.basket;
        if(basket.priceType === 0)
          basket.priceTypeName = 'Start-up 요금제';
        else
          basket.priceTypeName = 'Enterprise 요금제';
        return basket;
      },

      account_card() {
        let card = '';
        let cardCompanies = [
          {code: '01', name: '하나(외환)'},
          {code: '03', name: '롯데'},
          {code: '04', name: '현대'},
          {code: '06', name: '국민'},
          {code: '11', name: 'BC'},
          {code: '12', name: '삼성'},
          {code: '14', name: '신한'},
          {code: '21', name: '해외 VISA'},
          {code: '22', name: '해외마스터'},
          {code: '23', name: '해외 JCB'},
          {code: '26', name: '중국은련'},
          {code: '32', name: '광주'},
          {code: '33', name: '전북'},
          {code: '34', name: '하나'},
          {code: '35', name: '산업카드'},
          {code: '41', name: 'NH'},
          {code: '43', name: '씨티'},
          {code: '44', name: '우리'},
          {code: '48', name: '신협체크'},
          {code: '51', name: '수협'},
          {code: '52', name: '제주'},
          {code: '54', name: 'MG새마을금고체크'},
          {code: '55', name: '케이뱅크'},
          {code: '56', name: '카카오뱅크'},
          {code: '71', name: '우체국체크'},
          {code: '95', name: '저축은행체크'}
        ];

        card = this.findItem(cardCompanies, 'code', this.$store.getters.basket.card.code).name +
          ' / ' + this.$store.getters.basket.card.num;

        return card;
      },

      account_start_date() {
        return moment(this.$store.getters.basket.account_date).format('YYYY년 MM월 DD일');
      },

      account_next_date() {
        return moment(this.$store.getters.basket.account_date).add(1, 'month').format('YYYY년 MM월 DD일');
      },

      email() {
        return this.$store.getters.user.email;
      },

      imgStyle() {
        let deco = {
          width: '48px',
          height: '48px',
          backgroundImage: `url(${ this.basket.img })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }

        return deco;
      }
    }
  }
</script>

<style scoped>
  .lp-account-title,
  .lp-account-menu-title,
  .lp-total-price {
    color: rgb(57, 62, 70);
    font-size: 1.2rem;
  }

  .content-main-top-container {
    background-color: rgb(247, 247, 247);
  }

  .lp-account-title {
    background-color: white;
    padding: 28px;
    font-weight: 600;
    text-align: center;
  }

  .lp-account-menu-title {
    margin-bottom: 2px;
  }

  .lp-account-container {
    padding: 40px 0;
  }

  .lp-card {
    border: 1px solid rgb(229, 229, 229);
    border-radius: 3px;
    padding: 16px;
    background-color: white;
  }

  .lp-account-info {
    color: rgb(131, 131, 131);
    display: flex;
    justify-content: space-between;
    padding: 16px 12px 0 12px;
  }

  .lp-total-price {
    font-weight: 600;
  }

  .lp-input-box {
    background-color: #f5f5f5;
    border-radius: 3px;
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px;
  }

  .lp-input-box .prefix,
  .lp-input-box .suffix,
  .lp-info {
    color: rgb(131, 131, 131);
    font-size: 0.9rem;
  }

  .lp-input-box .prefix {
    margin: 0 12px;
  }

  .lp-input-box .suffix {
    margin-right: 12px;
    color: rgb(255, 102, 0);
  }

  input[type='text'] {
    height: 44px;
    border: 0;
    flex: 1;
    font-size: 1rem;
    padding: 0 12px;
  }

  input[type='checkbox'] {
    border-radius: 0;
    border: 1px solid #ddd;
    margin: 0 8px 12px 0;
  }

  input:focus {
    outline: 0;
  }

  .button {
    margin-top: 20px;
    background-color: rgb(255, 102, 0);
    color: white;
  }

  .info {
    border-top: 1px solid #ddd;
    padding: 36px 0;
    white-space: pre-wrap;
    font-size: 0.9rem;
    line-height: 1.8rem;
  }

  .lp-card-title {
    color: rgb(152, 152, 152);
    font-size: 0.9rem;
    margin-bottom: 4px;
  }

  th {
    text-align: center;
  }
  td:first-child {
    text-align: center;
  }

  td {
    text-align: right;
  }

  .lp-done-info {
    text-align: center;
    margin-bottom: 60px;
  }

  .lp-done-info p {
    margin-top: 12px;
  }
</style>
